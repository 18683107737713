<template>
  <el-dialog
    width="800px"
    title="Nhãn dáng món ăn"
    :visible="visible"
    :show-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="$emit('close')"
  >
    <div class="shared-index">
      <el-row
        class="table-title"
        type="flex"
        align="center"
        justify="space-between"
      >
        <el-row type="flex" align="center">
          <el-input
            v-model="searchText"
            placeholder="Tìm kiếm theo tên"
            size="small"
            class="mr-2"
            :disabled="loading"
            @input="handleSearch"
          ></el-input>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            :disabled="loading"
            @click="handleSearch"
          >Tìm kiếm</el-button>
        </el-row>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          :disabled="loading"
          @click="handleOpenForm(null)"
        >Thêm</el-button>
      </el-row>
      <el-table v-loading="loading" :data="listTag" style="width: 100%">
        <el-table-column prop="name" label="Tên">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column prop="description" label="Mô tả">
          <template slot-scope="scope">
            {{ scope.row.description }}
          </template>
        </el-table-column>
        <el-table-column
          label="Thao tác"
          width="100"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-tooltip content="Chỉnh sửa thông tin" placement="top">
              <el-button
                icon="el-icon-edit"
                type="primary"
                circle
                size="small"
                @click="handleOpenForm(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="Xóa nhãn dán" placement="top">
              <el-button
                icon="el-icon-delete"
                circle
                type="danger"
                size="small"
                @click="handleDeleteTag(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" align="middle" justify="end" class="pagination">
        <el-pagination
          :current-page.sync="page"
          :page-sizes="[10, 15, 20, 30]"
          :page-size.sync="limit"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleChangeSize"
          @current-change="handleChangePage"
        ></el-pagination>
      </el-row>
    </div>
    <span slot="footer">
      <el-button @click="$emit('close')">Đóng</el-button>
    </span>
    <tag-form-popup
      v-if="formVisible"
      :visible="formVisible"
      :tag="selectedTag"
      :language="language"
      @close="formVisible = false"
      @reload="loadData"
    ></tag-form-popup>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { getTags, deleteTag } from '@/services/tag'
import TagFormPopup from './TagForm.vue'

export default {
  name: 'ExportExcelPopup',
  components: {
    TagFormPopup
  },
  props: {
    visible: Boolean
  },
  data () {
    return {
      loading: false,
      page: 1,
      total: 0,
      limit: 10,
      searchText: '',
      listTag: [],
      selectedTag: null,
      formVisible: false
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      return {
        locale: this.language,
        page: 1,
        per_page: this.limit,
        name: this.searchText
      }
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      await getTags(this.params).then((response) => {
        this.listTag = response.result
        this.total = response.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleChangePage(page) {
      this.page = page
      this.loadData()
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
      this.loadData()
    },

    handleSearch(text) {
      this.searchText = text
      this.page = 1
      this.loadData()
    },

    handleOpenForm(tag) {
      this.selectedTag = tag
      this.formVisible = true
    },

    handleDeleteTag(id) {
      this.$confirm('Bạn có chắc chắn muốn xóa nhãn dán này?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(async () => {
        await deleteTag(id).then(() => {
          this.$notify.success({
            title: 'Thành công',
            message: 'Xóa nhãn dán thành công'
          })
          this.loadData()
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Xóa nhãn dán thất bại'
          })
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
