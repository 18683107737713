<template>
  <el-dialog
    top="80px"
    width="500px"
    class="create-children"
    :title="`Tạo mới ${label.toLowerCase()} con`"
    :visible="visible"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      label-position="top"
      :model="form"
      :rules="rules"
    >
      <el-form-item :label="`${label.toLowerCase()} cha`" prop="parentId">
        <el-select
          v-model="form.parentId"
          filterable
          remote
          :disabled="callingAPI || (detail && detail.type === type)"
          :placeholder="`Chọn ${label.toLowerCase()} cha`"
          :remote-method="searchPlace"
          :loading="searching"
        >
          <el-option
            v-for="p in places"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Tên" prop="name">
        <el-input v-model="form.name" :disabled="callingAPI" placeholder="Nhập vào tên để tạo mới"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="$emit('close')">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleCreateChildren">Tạo</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createPlace, listPlaces } from '@/services/place'
export default {
  name: 'CreateChildren',
  props: {
    visible: Boolean,
    type: String,
    label: String,
    detail: Object
  },
  data() {
    return {
      callingAPI: false,
      searching: false,
      searchText: '',
      places: [],
      form: {
        parentId: '',
        name: ''
      },
      rules: {
        parentId: [
          {
            required: true,
            message: `${this.label.toLowerCase()} cha không được để trống.`,
            trigger: ['blur, change']
          }
        ],
        name: [
          {
            required: true,
            message: 'Tên không được để trống.',
            trigger: ['blur, change']
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    params() {
      let p = {
        locale: this.language,
        place_types: this.type,
        only_parent: true,
        per_page: 10,
        search_text: this.searchText
      }
      if (this.detail) {
        p = {
          ...p,
          [`${this.detail.type}_id`]: this.detail.id
        }
      }
      return p
    },
    itemInput() {
      const payload = {
        name: this.form.name,
        locale: this.language,
        parent_id: this.form.parentId,
        place_type: this.type
      }
      if (this.detail) {
        payload[`${this.detail.type}_id`] = this.detail.id
      }
      return payload
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      if (this.detail) {
        this.places = this.detail.type === this.type ? [this.detail] : []
        this.form.parentId = this.detail.type === this.type ? this.detail.id : ''
      }
      if (!this.form.parentId) {
        this.getPlaces()
      }
    },
    getPlaces() {
      this.searching = true
      const request = listPlaces(this.params)
      request.then((res) => {
        this.places = res.result
        this.searching = false
      }).catch(() => {
        this.searching = false
      })
    },
    searchPlace(text) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces()
      }, 100)
    },
    handleCreateChildren() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          createPlace(this.itemInput).then(() => {
            this.callingAPI = false
            this.$emit('close')
            this.$emit('reload')
            this.$notify.success({
              title: 'Thông báo',
              message: 'Tạo mới thành công'
            })
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.create-children {
  .el-select {
    width: 100%;
  }
  .el-form-item__label {
    text-transform: capitalize;
  }
}
</style>
