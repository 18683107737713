<template>
  <el-dialog
    :visible="visible"
    title="Đồng bộ hóa dữ liệu"
    width="500px"
    close-on-press-escape
    close-on-click-modal
  >
    <el-row type="flex" align="middle" justify="center">
      <i class="el-icon-loading"></i>
    </el-row>
    <span slot="footer">
      <el-button :disabled="calling" @click="handleClose">Hủy</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SynchronizePopup',
  components: {},
  props: {
    visible: Boolean,
    calling: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
