<template>
  <el-dialog
    top="80px"
    width="500px"
    class="create-item"
    :title="'Tạo mới'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Tên" prop="name">
        <el-input v-model="form.name" :disabled="callingAPI" placeholder="Nhập vào tên để tạo mới"></el-input>
      </el-form-item>
      <el-form-item v-if="!['area', 'country'].includes(type)" label="Danh mục" prop="category">
        <el-select v-model="form.category" :disabled="callingAPI" filterable>
          <el-option v-for="c in categories" :key="c.id" :label="c.name" :value="c.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="['tour'].includes(type)" label="Đất nước" prop="countryId">
        <el-select
          v-model="form.countryId"
          filterable
          class="w-100"
          :disabled="callingAPI || placeDisabled"
        >
          <el-option v-for="t in countries" :key="t.id" :label="t.name" :value="t.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="['tour'].includes(type)" label="Khu vực" prop="areaId">
        <el-select
          v-model="form.areaId"
          class="w-100"
          filterable
          remote
          placeholder="Chọn khu vực"
          :disabled="callingAPI || placeDisabled"
        >
          <el-option v-for="p in areas" :key="p.id" :label="p.name" :value="p.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="['event'].includes(type)" label="Thời gian diễn ra" prop="times">
        <el-date-picker
          v-model="form.times"
          type="daterange"
          range-separator="-"
          start-placeholder="Ngày bắt đầu"
          end-placeholder="Ngày kết thúc"
          format="dd-MM-yyyy"
          value-format="yyyy-MM-ddT00:00:00"
          class="w-100"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="$emit('close')">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleCreateItem">Tạo</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createPlace, listPlaces } from '@/services/place'
import { getPlaceTypes } from '@/utils/filters'

export default {
  name: 'CreateItem',
  props: {
    visible: Boolean,
    type: String,
    placeType: Object,
    detail: Object
  },
  data() {
    return {
      callingAPI: false,
      searchText: '',
      searching: false,
      places: [],
      form: {
        name: '',
        category: '',
        placeType: '',
        place: '',
        times: '',
        countryId: '',
        areaId: ''
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Tên không được để trống.',
            trigger: ['blur, change']
          }
        ],
        category: [
          {
            required: true,
            message: 'Danh mục được để trống.',
            trigger: ['blur, change']
          }
        ],
        placeType: [
          {
            required: true,
            message: 'Loại điểm đến không được để trống.',
            trigger: ['blur, change']
          }
        ],
        place: [
          {
            required: true,
            message: 'Điểm đến không được để trống.',
            trigger: ['blur, change']
          }
        ],
        times: [
          {
            required: true,
            message: 'Thời gian diễn ra không được để trống.',
            trigger: ['blur, change']
          }
        ],
        countryId: [
          {
            required: true,
            message: 'Đất nước không được để trống.',
            trigger: ['blur, change']
          }
        ],
        areaId: [
          {
            required: true,
            message: 'Khu vực không được để trống.',
            trigger: ['blur, change']
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    areas() {
      return this.constant.constants.areas
    },
    countries() {
      return this.constant.constants.countries
    },
    placeDisabled() {
      return this.detail && !this.detail.detailType.hide
    },
    placeTypes() {
      return getPlaceTypes().filter((p) => p.type === 'place')
    },
    params() {
      let p = {
        locale: this.language,
        place_types: this.form.placeType,
        per_page: 10,
        search_text: this.searchText
      }
      if (this.detail) {
        p = {
          ...p,
          [`${this.detail.type}_id`]: this.detail.id
        }
      }
      return p
    },
    categories() {
      return this.constant.constants.place_categories.filter(
        (c) => c.place_type === this.type
      )
    },
    itemInput() {
      let data = {
        name: this.form.name,
        locale: this.language,
        category_id: this.form.category
      }
      if (['tour'].includes(this.type)) {
        data = {
          ...data,
          area_id: this.form.areaId,
          country_id: this.form.countryId,
          place_type: this.type
        }
      } else if (['post'].includes(this.type)) {
        data = {
          ...data,
          place_id: this.form.place,
          place_type: this.type
        }
      } else if (['area', 'country'].includes(this.type)) {
        data = {
          name: this.form.name,
          locale: this.language,
          place_type: this.type
        }
      } else if (['event'].includes(this.type)) {
        data = {
          ...data,
          ended_at: this.form.times[1],
          started_at: this.form.times[0],
          place_type: this.type
        }
      } else {
        data = {
          ...data,
          place_type: this.type
        }
      }
      if (this.detail && this.type !== this.detail.type && !this.placeDisabled) {
        data = {
          ...data,
          [`${this.detail.type}_id`]: this.detail.id
        }
      }
      return data
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.form = {
        name: '',
        category: this.categories[0] ? this.categories[0].id : ''
      }
      if (this.placeDisabled) {
        this.form.place = this.detail.id
        this.form.placeType = this.detail.type
        this.places = [this.detail]
      }
    },
    getPlaces() {
      this.searching = true
      const request = listPlaces(this.params)
      request.then((res) => {
        this.places = res.result
        this.searching = false
        if (!this.form.place) {
          this.form.place = this.places[0].id
        }
      }).catch(() => {
        this.searching = false
      })
    },
    handleChangePlaceType() {
      this.form.place = ''
      this.searchText = ''
      this.getPlaces()
    },

    searchPlace(text) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces()
      }, 100)
    },

    handleCreateItem() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          createPlace(this.itemInput).then(() => {
            this.callingAPI = false
            this.$emit('close')
            this.$emit('reload')
            this.$notify.success({
              title: 'Thông báo',
              message: 'Tạo mới thành công'
            })
            if (['country', 'area'].includes(this.type)) {
              this.$emit('reload-constant')
            }
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.create-item {
  .el-select {
    width: 100%;
  }
}
</style>
